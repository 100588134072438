exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1cFKi h1{font-size:32px;font-size:2rem;margin-top:150px;text-transform:uppercase}@media (max-width:768px){._1cFKi h1{margin-top:30px}}@media (max-height:480px){._1cFKi h1{margin-top:30px}}._1cFKi fieldset{margin-top:-10px}._1cFKi .a1OxH{font-size:28.8px;font-size:1.8rem;display:block;font-weight:400;line-height:normal}@media (max-width:480px){._1cFKi .a1OxH{font-size:1.4rem}}._1up9l{position:absolute;bottom:15px;right:15px;font-size:19.2px;font-size:1.2rem}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"loginForm": "_1cFKi",
	"subline": "a1OxH",
	"versionInfo": "_1up9l"
};