import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import { Field, reduxForm } from 'redux-form';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './LoginForm.scss';
import render from '../../ReduxForm/renderField';
import validations from '../../ReduxForm/validations';
import validationMessages from '../../ReduxForm/messages';
import authMessages from '../messages';

const messages = defineMessages({
  heading: {
    id: 'login.heading',
    defaultMessage: 'Login',
    description: 'Heading for login form',
  },
  info: {
    id: 'login.info',
    defaultMessage: `
      Login using your username and password
    `,
    description: 'Info text for login form',
  },
  submit: {
    id: 'login.submit',
    defaultMessage: 'Login',
    description: 'Submit button text in login form',
  },
});

const validate = values => {
  const errors = {};
  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...validationMessages.required} />;
  }
  if (!validations.required(values.password)) {
    errors.password = <FormattedMessage {...validationMessages.required} />;
  }

  return errors;
};

class LoginForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.arrayOf(PropTypes.string),
    intl: intlShape.isRequired,
    versionQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      version: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    errors: [],
  };

  render() {
    if (this.props.versionQuery.loading) return <div />;
    const { errors, intl, handleSubmit } = this.props;
    const { version } = this.props.versionQuery;
    return (
      <div className={s.loginForm}>
        <h1 style={{ margin: '0 0 5px 0' }}>
          <FormattedMessage {...messages.heading} />
        </h1>
        <span className={s.subline}>
          <FormattedMessage {...messages.info} />
        </span>
        <form className="default-form" onSubmit={handleSubmit}>
          <fieldset>
            <Field
              id="username"
              name="username"
              type="text"
              label={intl.formatMessage(authMessages.username)}
              component={render.renderInput}
            />
            <Field
              id="password"
              name="password"
              type="password"
              label={intl.formatMessage(authMessages.password)}
              component={render.renderInput}
            />
            {errors.length === 0 ? null : (
              <ul style={{ padding: '0', listStyleType: 'none' }}>
                {errors.map(err => (
                  <li key={err} className="bg-danger">
                    {err}
                  </li>
                ))}
              </ul>
            )}
            <div className="btn-group btn-group-justified">
              <div className="btn-group">
                <button
                  type="submit"
                  className="btn-secondary btn-round btn-large"
                >
                  <FormattedMessage {...messages.submit} />
                </button>
              </div>
            </div>
          </fieldset>
        </form>
        <div className={s.versionInfo}>Version: {version}</div>
      </div>
    );
  }
}

const versionQuery = gql`
  query versionQuery {
    version
  }
`;

export default compose(
  graphql(versionQuery, {
    name: 'versionQuery',
  }),
  reduxForm({
    form: 'login',
    validate,
    pure: false, // this is necessary to trigger form re-renders if the locale changes
  }),
  injectIntl,
  withStyles(s),
)(LoginForm);
